// ------home page
import { Component, Vue } from "vue-property-decorator";
import product1 from '@/assets/images/product1.png';
import product2 from '@/assets/images/product2.png';
import product3 from '@/assets/images/product3.png';
import product4 from '@/assets/images/product4.png';
import ToFooter from "@/components/footer/footer.vue";
import Header from "@/components/header/header.vue";

@Component({
  components: {
      ToFooter,
      Header,
  }
})
export default class Newsdetail extends Vue {
    private toptype:number=4;
    private topnumber:number=1;
    private page:number=1;
    private title:any;

    // 左侧菜单跳转
    toPage(index:number) {
        let owner=this;
        owner.toptype=index;
        if(index===1){
            this.$router.push({
                path: "/home",
                query: {}
            });
        }else if(index===2){
            this.$router.push({
                path: "/companyprofile",
                query: {}
            });
        }else if(index===3){
            this.$router.push({
                path: "/product",
                query: {}
            });
        }else if(index===4){
            this.$router.push({
                path: "/news",
                query: {}
            });
        }else if(index===5){
            this.$router.push({
                path: "/mine",
                query: {}
            });
        }
    }
    // 左侧菜单跳转
    totab(index:number) {
        let owner=this;
        owner.topnumber=index;
    }
    inputpage(num:number){
        this.page=num;
        console.log(this.page);
    }

    private created() {
        window.scrollTo(0, 0);
        const query = this.$route.query;
        this.title=query.title?query.title:'中石化智慧园区及智慧物流仓储平台';
    }
}
