import { Component, Vue,Prop } from "vue-property-decorator";

@Component
export default class Header extends Vue {
    @Prop({
        type: Number,
        default: false,
        required: false
    })
    toptype:number=1;

  private created() {
  }


    // 左侧菜单跳转
    toPage(index:number) {
        let owner=this;
        owner.toptype=index;
        if(index===1){
            this.$router.push({
                path: "/home",
                query: {}
            });
        }else if(index===2){
            this.$router.push({
                path: "/companyprofile",
                query: {}
            });
        }else if(index===3){
            this.$router.push({
                path: "/product",
                query: {}
            });
        }else if(index===4){
            this.$router.push({
                path: "/news",
                query: {}
            });
        }else if(index===5){
            this.$router.push({
                path: "/mine",
                query: {}
            });
        }
    }
}
