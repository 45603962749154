import { Component, Vue } from "vue-property-decorator";

@Component
export default class ToFooter extends Vue {

    number:number=1;
  private created() {
  }

    // 左侧菜单跳转
    toPage(index:number) {
        if(index===1){
            this.$router.push({
                path: "/home",
                query: {}
            });
        }else if(index===2){
            this.$router.push({
                path: "/companyprofile",
                query: {}
            });
        }else if(index===3){
            this.$router.push({
                path: "/product",
                query: {}
            });
        }else if(index===4){
            this.$router.push({
                path: "/news",
                query: {}
            });
        }else if(index===5){
            this.$router.push({
                path: "/mine",
                query: {}
            });
        }
    }
    gotogime(){
      this.number++;
        if(this.number>5){
            this.$router.push({
                path: "/gime",
                query: {}
            });
        }
    }
}
